import React from 'react';

import { trackGoal } from '../utilities/plausible';

const Newsletter = () => {
  return (
    <section className="call-to-action bg-gradient animated-fade">
      <div className="container">
        <div className="row">
          <div className="col-sm-9 col-xs-12 sm-text-center">
            <h3 className="white">Abonniere unseren kostenlosen Newsletter</h3>
          </div>
          <div className="col-sm-3 col-xs-12 cta-button text-right sm-text-center">
            <a
              href="/newsletter"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-lg btn-white"
              onClick={() => trackGoal('Newsletter: Visit Form')}
            >
              <span>Jetzt anmelden</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
