import { Link } from 'gatsby';
import React from 'react';

import IntroText from '../components/intro-text';
import KeyVisual from '../components/key-visual';
import Layout from '../components/layout';
import MetaData from '../components/meta-data';
import NewsOverview from '../components/news-overview';
import Newsletter from '../components/newsletter';

const IndexPage = (props) => {
  return (
    <Layout path={props.location.pathname}>
      <MetaData
        title="Coiffeur Cocoon | Dein Friseur für Hair & Make-up in Thun"
        description="In unserem Friseursalon erwartet dich helles, stilvolles Ambiente sowie ein exklusiver Service, der zum Entspannen und Verweilen einlädt."
        home={true}
      />

      <KeyVisual />

      <IntroText
        id="intro"
        title="Coiffeur Cocoon"
        subtitle="Dein Friseur für Hair & Make-up in Thun"
      >
        In unserem Friseursalon erwartet dich helles, stilvolles Ambiente sowie
        ein exklusiver Service, der zum Entspannen und Verweilen einlädt.
        Inspiriert von den neusten Trends, in einzigartiger Atmosphäre und
        voller Begeisterung setzen wir uns für deine individuellen Wünsche und
        deine Zufriedenheit ein. Denn deine Schönheit steht stets im Fokus
        unserer Arbeit.
        <br />
        <br />
        Termine kannst du jederzeit{' '}
        <a href="/termin-buchen" target="_blank" rel="noopener noreferrer">
          Online
        </a>
        , per <a href="tel:+41332211778">Telefon</a> oder{' '}
        <a
          href="https://api.whatsapp.com/send?phone=41332211778"
          target="_blank"
          rel="noopener noreferrer"
        >
          WhatsApp
        </a>{' '}
        unter der Nummer <a href="tel:+41332211778">033 221 17 78</a> oder via{' '}
        <Link to="/kontakt/">Kontaktformular</Link> vereinbaren.
        <br />
        <br />
        Wir freuen uns sehr auf dich!
      </IntroText>

      <NewsOverview />

      <Newsletter />
    </Layout>
  );
};

export default IndexPage;
