import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { trackGoal } from '../utilities/plausible';

const KeyVisual = () => {
  const { keyVisual } = useStaticQuery(
    graphql`
      {
        keyVisual: file(relativePath: { eq: "keyvisual.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 80
              layout: FULL_WIDTH
              placeholder: BLURRED
            )
          }
        }
      }
    `
  );

  return (
    <BackgroundImage
      Tag="section"
      className="page-title style-4"
      style={{
        backgroundPosition: '65% 50%',
      }}
      {...convertToBgImage(getImage(keyVisual))}
      preserveStackingContext={true}
    >
      <div className="container relative clearfix">
        <div className="title-holder">
          <div className="title-text text-center">
            <div className="title-inner">
              <h1 className="subheading">Fühle dich schön</h1>
              <a
                href="/termin-buchen"
                className="btn btn-lg btn-color"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  trackGoal('Booking: Visit Form', {
                    props: { source: 'Call to Action' },
                  })
                }
              >
                <span>Jetzt Termin buchen</span>
              </a>
            </div>
          </div>
        </div>

        <AnchorLink href="#intro" className="scroll-down" title="Zum Inhalt">
          <i className="fa fa-angle-down"></i>
        </AnchorLink>
      </div>
    </BackgroundImage>
  );
};

export default KeyVisual;
