import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

function getLink(node) {
  if (node.html || !node.frontmatter.link) {
    return node.fields.slug;
  } else {
    return node.frontmatter.link;
  }
}

const NewsOverview = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          limit: 3
        ) {
          edges {
            node {
              fields {
                slug
              }
              html
              frontmatter {
                title
                description
                link
                day: date(formatString: "DD", locale: "de")
                month: date(formatString: "MMM", locale: "de")
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <section className="section-wrap-sm from-blog bg-light">
      <div className="container">
        <div className="row heading-row">
          <div className="col-sm-12 text-center">
            <h2 className="heading">Neuigkeiten</h2>
          </div>
        </div>

        <div className="row">
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <article
              className="col-md-4 col-sm-6 col-xs-12"
              key={node.fields.slug}
            >
              <div className="entry-item card">
                <div className="entry-img">
                  <Link to={getLink(node)}>
                    <GatsbyImage
                      image={getImage(node.frontmatter.image)}
                      alt={node.frontmatter.title}
                    />
                  </Link>
                  <div className="entry-date">
                    {node.frontmatter.day}
                    <span>{node.frontmatter.month}</span>
                  </div>
                </div>
                <div className="entry-wrap">
                  <div className="entry">
                    <h4 className="entry-title">
                      <Link to={getLink(node)}>{node.frontmatter.title}</Link>
                    </h4>
                    <p>{node.frontmatter.description}</p>
                    {(node.html || node.frontmatter.link) && (
                      <p className="mt-20">
                        <Link to={getLink(node)} className="read-more">
                          Weiterlesen
                        </Link>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewsOverview;
